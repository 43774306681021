import React, { Component } from "react";
import BackgroundImage from "gatsby-background-image";
import { supportsObjectFit, supportsWebP, supportsSrcSet } from "js/util";

class LazyImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadying: false,
      isReady: false,
      showPlaceholder: false,
      isLoading: false,
      isLoaded: false,
      isObjectFit: supportsObjectFit(),
      isSrcSet: supportsSrcSet(),
      isWebP: -1
    }

    this.timeouts = [false, false]
    this.image = false
  }

  componentDidMount () {
    this.timeouts[0] = setTimeout(() => {
      this.setState({
        showPlaceholder: true
      });
    }, 300);

    supportsWebP().then(result => {
      this.setState({
        isWebP: result ? 1 : 0
      });
    });
  }

  componentDidUpdate () {
    if (
      this.state.isLoaded &&
      this.shouldLoad() &&
      !this.state.isReadying
    ) {
      this.setState({
        isReadying: true
      })

      this.timeouts[1] = setTimeout(() => {
        this.setState({
          isReady: true
        });
      }, 600);
    }

    if (~this.state.isWebP && !this.state.isLoading) {
      this.setState({
        isLoading: true
      });
      this.loadImage();
    }
  }

  shouldLoad() {
    return typeof this.props.shouldLoad !== "undefined"
      ? this.props.shouldLoad && this.props.src
      : this.props.src;
  }

  loadImage () {
    this.image = new Image()
    this.image.onload = () => {
      this.setState({
        isLoaded: true
      });
    };

    if (this.state.isSrcSet) {
      this.image.srcset = this.getSrcSet()
    } else {
      this.image.src = this.props.src
    }
  }

  getSrcSet() {
    return this.state.isWebP
      ? this.props.srcSetWebp || this.props.srcWebP || this.props.src
      : this.props.srcSet || this.props.src;
  }

  componentWillUnmount () {
    this.timeouts.forEach(timeout => {
      if (timeout) {
        clearTimeout(timeout)
      }
      return true
    })
    this.image.onload = () => {}
    this.image = false
  }

  render () {
    return (
      <>
        {this.state.isObjectFit ? (
          <div className={this.props.className}>
            <div
              className={
                "lazy-image" +
                (this.state.isReady ? " is-loaded" : "") +
                (this.state.showPlaceholder ? " show-placeholder" : "")
              }
            >
              {this.shouldLoad() && (
                <>
                  <img
                    className="lazy-image__placeholder"
                    src={this.props.base64}
                    alt={this.props.alt || ""}
                  />
                  <img
                    className="lazy-image__img"
                    src={this.props.src}
                    srcSet={this.getSrcSet()}
                    alt={this.props.alt || ""}
                  />
                </>
              )}
            </div>
            {this.props.children}
          </div>
        ) : (
          <BackgroundImage className={this.props.className} fluid={this.props}>
            {this.props.children}
          </BackgroundImage>
        )}
      </>
    );
  }
}

export default LazyImage;
